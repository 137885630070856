<template>
    <div class="height">
        <div class="web">
            <div class="new-bg" :class="`fen${page} ${page==module.data.length+10?'last':''}`" v-if="info">
                <vue-header title="健康报告" :report="true" :fixed="true" :notoken="noToken"/>
                <div class="erect">
                    <div class="new-box y1">
                        <div class="tips">郑重提醒：本报告不作医学诊断及购药依据之用！</div>
                        <div class="newre-port">
                            <img :src="user.url" v-if="user.url" />
                            <img src="../../../../images/user.png" v-else />
                        </div>
                        <div class="newre-name">{{user.name?user.name:(user.nikeName?user.nikeName:'')}}</div>
                        <div class="newre-info">
                            <div class="use-mess flex flex-x flex-y">
                                <p>性别：{{info.sex==1?'男':'女'}}</p>
                                <div class="line"></div>
                                <p>年龄：{{info.age}}岁</p>
                                <div class="line"></div>
                                <p>身高：{{info.height}}cm</p>
                                <div class="line"></div>
                                <p>体重：{{info.weight}}kg</p>
                                <div class="fat blue" v-if="MBItext(info.bmi)=='偏瘦'">{{ MBItext(info.bmi) }}</div>
                                <div class="fat green" v-if="MBItext(info.bmi)=='正常'">{{ MBItext(info.bmi) }}</div>
                                <div class="fat" v-if="MBItext(info.bmi)=='偏胖'">{{ MBItext(info.bmi) }}</div>
                                <div class="fat orange" v-if="MBItext(info.bmi)=='肥胖'">{{ MBItext(info.bmi) }}</div>
                                <div class="fat red" v-if="MBItext(info.bmi)=='重度肥胖'">{{ MBItext(info.bmi) }}</div>
                            </div>
                        </div>
                        <div class="risk">
                            风险提示
                        </div>
                        <div class="assessment">
                            <div>
                                <span>健康平衡指数：</span>
                                <span class="orange" :class="{green:info.sick_text_array.length==1&&info.sick_text_array=='平衡'}">{{info.score}}分</span>
                            </div>
                            <div>
                                <span>中医偏颇证侯：</span>
                                <span class="orange" :class="{green:info.sick_text_array.length==1&&info.sick_text_array=='平衡'}">{{info.sick_text}}</span>
                            </div>
                            <div>
                                <span>常见表现：</span>
                                <span class="orange">{{info.viscera.syndrome_show}}</span>
                            </div>
                            <div v-if="info.age<18 || !(info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡')">
                                <span>重大疾病风险预警：</span>
                                <span class="orange">{{info.big.join('、')}}</span>
                            </div>
                            <div v-if="info.age<18 || !(info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡')">
                                <span>重点预防：</span>
                                <span class="orange">{{info.little.join('、')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y2">
                        <div class="risk">
                            健康平衡指数
                        </div>
                        <div class="assessment no-text">
                            <div id="main" class="balance-meter"></div>
                            <div class="condition" v-if="0<info.score && info.score<=50">
                                可能患有疾病，建议<span class="orange">就医检查</span>
                            </div>
                            <div class="condition" v-if="50<info.score && info.score<=65">
                                身体极不平衡，建议<span class="orange">马上调理</span>
                            </div>
                            <div class="condition" v-if="65<info.score && info.score<=80">
                                身体较不平衡，建议<span class="orange">马上调理</span>
                            </div>
                            <div class="condition" v-if="80<info.score && info.score<=90">
                                身体较为平衡，建议<span class="green">适当调理</span>
                            </div>
                            <div class="condition" v-if="90<info.score && info.score<=100">
                                身体平衡，建议<span class="green">适当调理</span>
                            </div>
                            <div class="newre-classify newre-condie">
                                <div>
                                    <span class="red newre-width">0-50分</span>可能患有疾病，建议<span class="red">就医检查</span>
                                </div>
                                <div>
                                    <span class="orange newre-width">50-65分</span>身体极不平衡，建议<span class="orange">马上调理</span>
                                </div>
                                <div>
                                    <span class="yellow newre-width">65-80分</span>身体较不平衡，建议<span class="yellow">马上调理</span>
                                </div>
                                <div>
                                    <span class="green newre-width">80-90分</span>身体较为平衡，建议<span class="green">适当调理</span>
                                </div>
                                <div>
                                    <span class="cyan newre-width">90-100分</span>身体平衡，建议<span class="cyan">适当调理</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y3">
                        <div class="risk">
                            中医证候
                        </div>
                        <div class="assessment">
                            <div class="new-title">您当前的中医证候</div>
                            <div class="sick-list flex">
                                <div class="new-sick green-sick" v-if="info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡'">平衡</div>
                                <div v-else  v-for="( item, index ) in info.sick_text_array" v-bind:key="index" class="new-sick">
                                    {{item}}
                                </div>
                            </div>
                            <div class="sick-dosc">
                                <div v-for="( item, index ) in info.sick_text_array" v-bind:key="index">
                                    <div class="sick-title">{{item}}</div>
                                    <div v-html="JSON.parse(info.viscera.syndrome_desc)[item]" class="sick-text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y4">
                        <div class="risk">
                            体重指数(BMI)
                        </div>
                        <div class="assessment no-text">
                            <div class="risk-weight flex">
                                <div class="my-weight flex flex-x flex-y">
                                    <div>
                                        <div class="exponent">我的指数</div>
                                        <div class="weight-size">{{ info.bmi }}</div>
                                        <span class="weight-fat blue" v-if="MBItext(info.bmi)=='偏瘦'">{{ MBItext(info.bmi) }}</span>
                                        <span class="weight-fat green" v-if="MBItext(info.bmi)=='正常'">{{ MBItext(info.bmi) }}</span>
                                        <span class="weight-fat" v-if="MBItext(info.bmi)=='偏胖'">{{ MBItext(info.bmi) }}</span>
                                        <span class="weight-fat orange" v-if="MBItext(info.bmi)=='肥胖'">{{ MBItext(info.bmi) }}</span>
                                        <span class="weight-fat red" v-if="MBItext(info.bmi)=='重度肥胖'">{{ MBItext(info.bmi) }}</span>
                                    </div>
                                </div>
                                <div class="my-weight flex-1 noWeight flex flex-x flex-y">
                                <div>
                                    <div class="exponent">我的合适体重</div>
                                    <div class="weight-size">{{ info.suit_weight }}<span>kg</span></div>
                                </div>
                            </div>
                            </div>
                            <div class="weight-thin flex">
                                <div class="thin flex flex-x flex-y">
                                    <div>
                                        <div class="exponent">偏瘦</div>
                                        <div class="weight-size">&lt;18.5</div>
                                    </div>
                                </div>
                                <div class="thin flex flex-x flex-y">
                                    <div>
                                        <div class="exponent">正常</div>
                                        <div class="weight-size">18.5～23.9</div>
                                    </div>
                                </div>
                                <div class="thin flex flex-x flex-y">
                                    <div>
                                        <div class="exponent">偏胖</div>
                                        <div class="weight-size">24～26.9</div>
                                    </div>
                                </div>
                                <div class="thin flex flex-x flex-y">
                                    <div>
                                        <div class="exponent">肥胖</div>
                                        <div class="weight-size">27～29.9</div>
                                    </div>
                                </div>
                                <div class="thin flex flex-x flex-y">
                                    <div>
                                        <div class="exponent">重度肥胖</div>
                                        <div class="weight-size">≥30</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y5" v-if="info.index_list">
                        <div class="risk">
                            健康趋势图
                        </div>
                        <!-- <div class="trend7">显示最近7天数据趋势</div>
                        <div v-if="info.index_list.score">
                            <div class="healthy-1 flex">
                                <div class="healthy">健康平衡指数</div>
                            </div>
                            <div class="trend-alert">指数越高，身体健康越平衡</div>
                            <div class="balance-meter broken" id="broken"></div>
                        </div> -->
                        <div v-if="info.index_list.bmi" class="healthy-bmi">
                            <div class="healthy-1 flex">
                                <div class="healthy">体重指数(BMI)</div>
                            </div>
                            <div class="trend-alert">体重指数（BMI）= 体重/身高 ^2</div>
                            <div class="balance-meter broken" id="weight"></div>
                        </div>
                    </div>
                    <div class="new-box new-tips y6">
                        <div class="risk risk-max">
                            身体健康状态综合评估
                        </div>
                        <div class="assessment">
                            <div class="sick-dosc">
                                <div v-html="info.check_bmi" class="bmi-dosc"></div>
                            </div>
                            <div class="sick-dosc">
                                <div class="bmi-dosc">
                                    <div class="bmi-text">您的健康平衡指数为<span>{{info.score}}</span>分，对于<span>{{ info.age }}</span>岁的<span>{{ info.sex == 2 ? "女" : "男" }}性</span>，这表明</div>
                                    <div class="bmi-text" v-html="info.check_estimate" ></div>
                                </div>
                            </div>
                            <div class="sick-dosc">
                                <div class="affect">易受影响的脏腑组织</div>
                                <div v-html="info.viscera.viscera" class="bmi-dosc"></div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y7">
                        <div class="risk risk-max">
                            身体各系统功能强弱表
                        </div>
                        <div class="assessment">
                            <div class="table-mark">
                                <table class="newre-table">
                                    <thead>
                                    <tr>
                                        <th>系统功能名称</th>
                                        <th>指数（5～1）</th>
                                    </tr>  
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item , index) in info.system" :key="index">
                                        <td>{{index}}</td>
                                        <td>{{item}}</td>
                                    </tr>
                                    </tbody>  
                                </table>
                            </div>
                            <div class="sick-dosc">
                                <div class="affect">指数说明</div>
                                <div>
                                    <p>"5"表示处于最佳状态</p>
                                    <p>"4"表示处于健康状态</p>
                                    <p>"3"表示处于轻度亚健康状态</p>
                                    <p>"2"表示处于重度亚健康状态</p>
                                    <p>"1"表示处于异常状态</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="new-box new-tips y8" v-if="info.age<18 || !(info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡')">
                        <div class="risk risk-max">
                            健康风险提示
                        </div>
                        <div class="assessment">
                            <div class="sick-dosc">
                                <div class="bmi-dosc">
                                    <div class="bmi-text">根据您的性别年龄和上述各项健康指标，结合临床大数据研究显示，相对于同年龄段的人群您患<span>{{info.big.join('、')}}</span>的概率更高。大部分疾病的发生和传变都是一个漫长的过程，根本原因在于身体健康状态的失衡。如果诸多轻症长久不调理或潜藏未觉，就会增加向重疾恶化的风险</div>
                                    <div>建议您警惕以下重大疾病：<span>{{info.little.join('、')}}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y9" v-if="info.age<18||!(info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡')">
                        <div class="risk risk-max">
                            重大疾病风险预警
                        </div>
                        <div class="assessment">
                            <div v-for="(items, index) in info.bigData" :key="index">
                                <div class="new-title">{{items.name}}</div>
                                <div v-for="(item, index) in items.list" :key="index">
                                    <div class="sick-dosc" v-if="item.risk_index">
                                        <div class="affect">{{item.disease_name}}</div>
                                        <div class="sick-right">
                                            <div v-if="item.risk_index<40">低风险（{{item.risk_index}}）</div>
                                            <div v-if="40<=item.risk_index&&item.risk_index<60" class="centre">中风险（{{item.risk_index}}）</div>
                                            <div v-if="60<=item.risk_index&&item.risk_index<75" class="centreUp">中高风险（{{item.risk_index}}）</div>
                                            <div v-if="75<=item.risk_index" class="high">高风险（{{item.risk_index}}）</div>
                                        </div>
                                        <div class="bmi-dosc">
                                            <div class="bmi-text" v-html="item.disease_money"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="new-box new-tips y10">
                        <div class="risk risk-max">
                            风险防范方案
                        </div>
                        <div class="assessment">
                            <div class="sick-dosc">
                                <div>多数疾病的发生发展都有漫长的过程，而前期都是身体功能性的异常，还未发展到器官上器质性的病变，所以通过体检很难发现。等体检出疾病，很多都已是晚期。
            因此，我们需要尽早预防疾病，在身体刚刚处于功能性异常时，及时调理，恢复最佳平衡状态，提高自身免疫力，治未病。</div>
                                <div class="bmi-dosc">根据中医“辨证论治”理论，调理需要根据每个人的不同身体状态进行辨证及方案设计。<span>以下是根据您目前的综合健康状态科学设计的个性化“辨证养生”调理方案。</span></div>
                            </div>
                        </div>
                    </div>
                    <!-- 药茶养生、艾灸养生、经络养生、药膳养生、六茶养生、经方、音乐养生、起居指导 -->
                    <div class="new-box new-tips" :class="`y${index+11}`" v-for="(items, index) in module.data" :key="index">
                        <div class="risk">
                            {{items.module_name}}
                        </div>
                        <div class="assessment">
                            <div class="sick-list flex">
                                <div class="new-sick green-sick" v-if="info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡'">平衡</div>
                                <div v-else :class="{active:item1.active}"  v-for="( item1, index1 ) in items.description_list" v-bind:key="index1" class="new-sick new-easy" @click="()=>change({
                                    index:index,
                                    index1:index1
                                })">
                                    {{item1.syndrome_name}}
                                </div>
                            </div>
                            <div class="sick-list flex">
                                <div v-for="( item2, index2 ) in items.description_list" v-bind:key="index2">
                                    <div v-if="item2.list && item2.active" class="flex flex-v">
                                        <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                            <div class="new-sick new-green " :class="{active:item3.active}" v-if="item3.module_value[0].value" @click="()=>changeTwo({
                                                index:index,
                                                index1:index2,
                                                index2:index3
                                            })">{{item3.module_value[0].value}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="( item2, index3 ) in items.description_list" v-bind:key="index3">
                                <div v-if="item2.list && item2.active">
                                    <div  v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                        <div v-if="item3.active" class="sick-dosc">
                                            <div v-if="items.is_need_audio==1" class="sick-audio">
                                                <div v-for="( audio, index6 ) in item3.audio" v-bind:key="index6">
                                                    <audio :src="audio" controls="controls">
                                                        您的浏览器不支持html5的audio标签
                                                    </audio>
                                                </div>
                                            </div>
                                            <div v-for="( item4, index4 ) in item3.module_value" v-bind:key="index4">
                                                <div v-if="index4>0">
                                                    <div class="sick-title">{{item4.title}}</div>
                                                    <div class="sick-text" v-html="item4.value"></div>
                                                </div>
                                            </div>
                                            <div v-if="items.is_need_image==1" class="sick-img">
                                                <div v-for="( img, index5 ) in item3.images" v-bind:key="index5">
                                                    <img :src="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div  v-if="items.is_show_goods==1">
                                        <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                            <div v-if="item3.active">
                                                <div class="syndrome-shop flex" v-if="item3.goodsURL" @click="()=>href(item3.goodsURL)">
                                                    <div class="syndrome-tea flex flex-1" >
                                                        <div class="syndrome-img" v-if="item3.goodsImg">
                                                            <img :src="item3.goodsImg"/>
                                                        </div>
                                                        <div class="tea-box flex-1" v-if="item3.goods_name && item3.goods_price">
                                                            <p>{{item3.goods_name}}</p>
                                                            <i><span>¥</span>{{item3.goods_price}}</i>
                                                        </div>
                                                    </div>
                                                    <div class="syndrome-look">前往查看</div>
                                                </div>
                                                <div v-if="items.is_show_goodthing==1 && item3.goods_more" class="syndrome-mose" @click="()=>href(item3.goods_more)">
                                                    更多好物
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fen-page">
                        <div class="s-page">
                            <div class="prev" @click="()=>pageFun({
                                page:page-1,
                                add:-1
                            })">上一页</div>
                        </div>
                        <div class="s-page">
                            <div class="next" @click="()=>pageFun({
                                page:page+1,
                                add:1
                            })">下一页</div>
                        </div>
                    </div>
                </div>
                <div class="horizontal">
                    <div class="hori-box y1">
                        <div class="hori-tips">郑重提醒：本报告不作医学诊断及购药依据之用</div>
                        <div class="flex flex-x">
                            <div class="hori-white no-white">
                                <div class="hori-user flex flex-y">
                                    <div class="hori-title flex-1">
                                        健康指数
                                    </div>
                                    <div class="hori-info flex flex-y">
                                        <div class="hori-item">性别：{{info.sex==1?'男':'女'}}</div>
                                        <div class="line"></div>
                                        <div class="hori-item">年龄：{{info.age}}岁</div>
                                        <div class="line"></div>
                                        <div class="hori-item">身高：{{info.height}}cm</div>
                                        <div class="line"></div>
                                        <div class="hori-item">体重：{{info.weight}}kg</div>
                                    </div>
                                </div>
                                <div class="health-num flex">
                                    <div class="flex-1">
                                        <div class="hori-fraction">
                                            {{info.score}}
                                            <span>分</span>
                                        </div>
                                        <div class="newre-classify">
                                            <div class="flex">
                                                <div class="classify red">0-50分</div>
                                                <div class="flex-1">可能患有疾病，建议<span class="red">就医检查</span></div>
                                            </div>
                                            <div class="flex">
                                                <div class="classify orange">50-65分</div>
                                                <div class="flex-1">身体极不平衡，建议<span class="orange">马上调理</span></div>
                                            </div>
                                            <div class="flex">
                                                <div class="classify yellow">65-80分</div>
                                                <div class="flex-1">身体较不平衡，建议<span class="yellow">马上调理</span></div>
                                            </div>
                                            <div class="flex">
                                                <div class="classify greet1">80-90分</div>
                                                <div class="flex-1">身体较为平衡，建议<span class="greet1">适当调理</span></div>
                                            </div>
                                            <div class="flex">
                                                <div class="classify greet2">90-100分</div>
                                                <div class="flex-1">身体平衡，建议<span class="greet2">适当调理</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="health-img">
                                        <img src="../../../../images/doctor.png"/>
                                    </div>
                                </div>
                                <div class="symptom-s">
                                    <h6>当前的证候为</h6>
                                    <div class="mptom flex">
                                        <div class="mptom-info" v-for="(item, index) in info.sick_text.split(',')" v-bind:key="index">
                                            {{item}}
                                        </div>
                                    </div>
                                    <div class="symptom-tip">
                                        建议您每周评估一次，密切关注自己的身体状态，及时进行辨证调理，治未病。
                                    </div>
                                </div>
                            </div>
                            <div class="hori-null flex-1"></div>
                        </div>
                    </div>
                    <div class="hori-box y2">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    体重指数（BMI）
                                </div>
                                <div class="risk-weight flex">
                                    <div class="my-weight flex flex-x flex-y">
                                        <div>
                                            <div class="exponent">我的指数</div>
                                            <div class="weight-size">{{ info.bmi }}</div>
                                            <span class="weight-fat blue" v-if="MBItext(info.bmi)=='偏瘦'">{{ MBItext(info.bmi) }}</span>
                                            <span class="weight-fat green" v-if="MBItext(info.bmi)=='正常'">{{ MBItext(info.bmi) }}</span>
                                            <span class="weight-fat" v-if="MBItext(info.bmi)=='偏胖'">{{ MBItext(info.bmi) }}</span>
                                            <span class="weight-fat orange" v-if="MBItext(info.bmi)=='肥胖'">{{ MBItext(info.bmi) }}</span>
                                            <span class="weight-fat red" v-if="MBItext(info.bmi)=='重度肥胖'">{{ MBItext(info.bmi) }}</span>
                                        </div>
                                    </div>
                                    <div class="my-weight flex-1 noWeight flex flex-x flex-y">
                                        <div>
                                            <div class="exponent">我的合适体重</div>
                                            <div class="weight-size">{{ info.suit_weight }}<span>kg</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex report-fat">
                                    <div class="flex-1 flex flex-x flex-y">
                                        <div>
                                            <div class="fat-title">偏廋</div>
                                            <div class="fat-text">&lt;18.5</div>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex flex-x flex-y">
                                        <div>
                                            <div class="fat-title">正常</div>
                                            <div class="fat-text">18.5~22.9</div>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex flex-x flex-y">
                                        <div>
                                            <div class="fat-title">偏胖</div>
                                            <div class="fat-text">23~24.9</div>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex flex-x flex-y">
                                        <div>
                                            <div class="fat-title">肥胖</div>
                                            <div class="fat-text">25~29.9</div>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex flex-x flex-y">
                                        <div>
                                            <div class="fat-title">重度肥胖</div>
                                            <div class="fat-text">≥30</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y3">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    健康平衡指数
                                </div>
                                <div class="balance-meter ance-top" id="mainwidth"></div>
                                <div class="condition condition-rol" v-if="0<info.score && info.score<=50">
                                    可能患有疾病，建议<span class="orange">就医检查</span>
                                </div>
                                <div class="condition condition-rol" v-if="50<info.score && info.score<=65">
                                    身体极不平衡，建议<span class="orange">马上调理</span>
                                </div>
                                <div class="condition condition-rol" v-if="65<info.score && info.score<=80">
                                    身体较不平衡，建议<span class="orange">马上调理</span>
                                </div>
                                <div class="condition condition-rol" v-if="80<info.score && info.score<=90">
                                    身体较为平衡，建议<span class="green">适当调理</span>
                                </div>
                                <div class="condition condition-rol" v-if="90<info.score && info.score<=100">
                                    身体平衡，建议<span class="green">适当调理</span>
                                </div>
                                <div class="newre-classify newre-condie newre-rol">
                                    <div class="flex">
                                        <div class="newre-left">
                                            <span class="red newre-width">0-50分</span>可能患有疾病，建议<span class="red">就医检查</span>
                                        </div>
                                        <div>
                                            <span class="orange newre-width">50-65分</span>身体极不平衡，建议<span class="orange">马上调理</span>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="newre-left">
                                            <span class="yellow newre-width">65-80分</span>身体较不平衡，建议<span class="yellow">马上调理</span>
                                        </div>
                                        <div>
                                            <span class="green newre-width">80-90分</span>身体较为平衡，建议<span class="green">适当调理</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="cyan newre-width">90-100分</span>身体平衡，建议<span class="cyan">适当调理</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y4">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                 <div class="assessment assessment-rol">
                                    <div class="new-title">您当前的中医证候</div>
                                    <div class="sick-list flex">
                                        <div class="new-sick green-sick" v-if="info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡'">平衡</div>
                                        <div v-else  v-for="( item, index ) in info.sick_text_array" v-bind:key="index" class="new-sick">
                                            {{item}}
                                        </div>
                                    </div>
                                    <div class="sick-dosc">
                                        <div v-for="( item, index ) in info.sick_text_array" v-bind:key="index">
                                            <div class="sick-title">{{item}}</div>
                                            <div v-html="JSON.parse(info.viscera.syndrome_desc)[item]" class="sick-text"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y5" v-if="info.index_list">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1" >
                                    健康趋势图
                                </div>
                                <div class="trend7">显示最近7天数据趋势</div>
                                <div class="flex">
                                    <div class="flex-1" v-if="info.index_list.score">
                                        <div class="healthy-1 flex">
                                            <div class="healthy">健康平衡指数</div>
                                        </div>
                                        <div class="trend-alert">指数越高，身体健康越平衡</div>
                                        <div class="balance-meter broken broken-top" id="y-broken"></div>
                                    </div>
                                    <div v-if="info.index_list.bmi" class="healthy-bmi flex-1">
                                        <div class="healthy-1 flex">
                                            <div class="healthy">体重指数(BMI)</div>
                                        </div>
                                        <div class="trend-alert">体重指数（BMI）= 体重/身高 ^2</div>
                                        <div class="balance-meter broken broken-top" id="y-weight"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y6">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    身体健康状态综合评估
                                </div>
                                <div class="hori-balance">
                                    <div class="sick-dosc">
                                        <div v-html="info.check_bmi" class="bmi-dosc"></div>
                                    </div>
                                    <div class="sick-dosc">
                                        <div class="bmi-dosc">
                                            <div class="bmi-text">您的健康平衡指数为<span>{{info.score}}</span>分，对于<span>{{ info.age }}</span>岁的<span>{{ info.sex == 2 ? "女" : "男" }}性</span>，这表明</div>
                                            <div class="bmi-text" v-html="info.check_estimate" ></div>
                                        </div>
                                    </div>
                                    <div class="sick-dosc">
                                        <div class="affect">易受影响的脏腑组织</div>
                                        <div v-html="info.viscera.viscera" class="bmi-dosc"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y7">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    身体各系统功能强弱表
                                </div>
                                <div class="hori-top flex">
                                    <div class="table-mark hori-table">
                                        <table class="newre-table">
                                            <thead>
                                            <tr>
                                                <th>系统功能名称</th>
                                                <th>指数（5～1）</th>
                                            </tr>  
                                            </thead>
                                            <tbody>
                                            <tr v-for="(item , index) in info.system" :key="index">
                                                <td>{{index}}</td>
                                                <td>{{item}}</td>
                                            </tr>
                                            </tbody>  
                                        </table>
                                    </div>
                                    <div class="sick-left flex-1">
                                        <div class="sick-dosc">
                                            <div class="affect">指数说明</div>
                                            <div>
                                                <p>"5"表示处于最佳状态</p>
                                                <p>"4"表示处于健康状态</p>
                                                <p>"3"表示处于轻度亚健康状态</p>
                                                <p>"2"表示处于重度亚健康状态</p>
                                                <p>"1"表示处于异常状态</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y8" v-if="info.age<18 || !(info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡')">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    健康风险提示
                                </div>
                                <div class="hori-balance">
                                    <div class="sick-dosc">
                                        <div class="bmi-dosc">
                                            <div class="bmi-text">根据您的性别年龄和上述各项健康指标，结合临床大数据研究显示，相对于同年龄段的人群您患<span>{{info.big.join('、')}}</span>的概率更高。大部分疾病的发生和传变都是一个漫长的过程，根本原因在于身体健康状态的失衡。如果诸多轻症长久不调理或潜藏未觉，就会增加向重疾恶化的风险</div>
                                            <div>建议您警惕以下重大疾病：<span>{{info.little.join('、')}}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y9" v-if="info.age<18 || !(info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡')">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    重大疾病风险预警
                                </div>
                                <div class="hori-balance">
                                    <div v-for="(items, index) in info.bigData" :key="index">
                                        <div class="new-title">{{items.name}}</div>
                                        <div v-for="(item, index) in items.list" :key="index">
                                            <div class="sick-dosc" v-if="item.risk_index">
                                                <div class="affect">{{item.disease_name}}</div>
                                                <div class="sick-right">
                                                    <div v-if="item.risk_index<40">低风险（{{item.risk_index}}）</div>
                                                    <div v-if="40<=item.risk_index&&item.risk_index<60" class="centre">中风险（{{item.risk_index}}）</div>
                                                    <div v-if="60<=item.risk_index&&item.risk_index<75" class="centreUp">中高风险（{{item.risk_index}}）</div>
                                                    <div v-if="75<=item.risk_index" class="high">高风险（{{item.risk_index}}）</div>
                                                </div>
                                                <div class="bmi-dosc">
                                                    <div class="bmi-text" v-html="item.disease_money"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hori-box y10">
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    风险防范方案
                                </div>
                                <div class="hori-balance">
                                    <div class="sick-dosc">
                                        <div>多数疾病的发生发展都有漫长的过程，而前期都是身体功能性的异常，还未发展到器官上器质性的病变，所以通过体检很难发现。等体检出疾病，很多都已是晚期。
                    因此，我们需要尽早预防疾病，在身体刚刚处于功能性异常时，及时调理，恢复最佳平衡状态，提高自身免疫力，治未病。</div>
                                        <div class="bmi-dosc">根据中医“辨证论治”理论，调理需要根据每个人的不同身体状态进行辨证及方案设计。<span>以下是根据您目前的综合健康状态科学设计的个性化“辨证养生”调理方案。</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 药茶养生、艾灸养生、经络养生、药膳养生、六茶养生、经方、音乐养生、起居指导 -->
                    <div class="hori-box" :class="`y${index+11}`" v-for="(items, index) in module.data" :key="index">
                        <div class="flex horis-sick">
                            <div v-if="info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡'" class="equili-brium">平衡</div>
                            <div v-else class="equili-brium" :class="{active:item1.active}"  v-for="( item1, index1 ) in items.description_list" v-bind:key="index1" @click="()=>change({
                                index:index,
                                index1:index1
                            })">
                                {{item1.syndrome_name}}
                            </div>
                        </div>
                        <div class="flex flex-x">
                            <div class="hori-white ">
                                <div class="hori-title flex-1">
                                    {{items.module_name}}
                                </div>
                                <div class="sick-list horis-text flex">
                                    <div v-for="( item2, index2 ) in items.description_list" v-bind:key="index2">
                                        <div v-if="item2.list && item2.active" class="flex flex-v">
                                            <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                                <div class="new-sick new-green " :class="{active:item3.active}" v-if="item3.module_value[0].value" @click="()=>changeTwo({
                                                    index:index,
                                                    index1:index2,
                                                    index2:index3
                                                })">{{item3.module_value[0].value}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hori-balance">
                                    <div v-for="( item2, index3 ) in items.description_list" v-bind:key="index3">
                                        <div v-if="item2.list && item2.active">
                                            <div  v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                                <div v-if="item3.active" class="sick-dosc">
                                                    <div v-if="items.is_need_audio==1" class="sick-audio">
                                                        <div v-for="( audio, index6 ) in item3.audio" v-bind:key="index6">
                                                            <audio :src="audio" controls="controls">
                                                                您的浏览器不支持html5的audio标签
                                                            </audio>
                                                        </div>
                                                    </div>
                                                    <div class="flex">
                                                        <div v-if="items.is_need_image==1" class="sicks-img">
                                                            <div v-for="( img, index5 ) in item3.images" v-bind:key="index5">
                                                                <img :src="img" />
                                                            </div>
                                                        </div>
                                                        <div class="flex-1">
                                                            <div v-for="( item4, index4 ) in item3.module_value" v-bind:key="index4">
                                                                <div v-if="index4>0">
                                                                    <div class="sick-title">{{item4.title}}</div>
                                                                    <div class="sick-text" v-html="item4.value"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  v-if="items.is_show_goods==1">
                                                <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3">
                                                    <div v-if="item3.active">
                                                        <div class="syndrome-shop flex" v-if="item3.goodsURL" @click="()=>href(item3.goodsURL)">
                                                            <div class="syndrome-tea flex flex-1" >
                                                                <div class="syndrome-img" v-if="item3.goodsImg">
                                                                    <img :src="item3.goodsImg"/>
                                                                </div>
                                                                <div class="tea-box flex-1" v-if="item3.goods_name && item3.goods_price">
                                                                    <p>{{item3.goods_name}}</p>
                                                                    <i><span>¥</span>{{item3.goods_price}}</i>
                                                                </div>
                                                            </div>
                                                            <div class="syndrome-look">前往查看</div>
                                                        </div>
                                                        <div v-if="items.is_show_goodthing==1 && item3.goods_more" class="syndrome-mose" @click="()=>href(item3.goods_more)">
                                                            更多好物
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="navclickToo">
                        <li>
                            <div class="hori-prev" @click="()=>pageFun({
                                page:page-1,
                                add:-1
                            })">上一页</div>
                        </li>
                        <li>
                            <div class="hori-next" @click="()=>pageFun({
                                page:page+1,
                                add:1
                            })">下一页</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="loading" class="load-ur flex flex-x flex-y">
                <van-loading size="60" text-color="#07B49A"/>
            </div>
            <div v-if="login">
                <vue-popup :showFun="loginFun" :callback="refrFun" :token="this.$route.params.id" />
            </div>
            <div v-if="history">
                <vue-popup :showFun="noLoginFun" url="reportList" :login="true" :birthday="info.birthday" :token="this.$route.params.id" />
            </div>
            <div v-if="prescribe">
                <vue-popup :showFun="prescribeFun" :callback="presFun" :token="this.$route.params.id" :prescribe="true" :skipFun="skipFun" />
            </div>
            <div class="loading black flex flex-x flex-y" v-if="send" @click="sendStateFun('send')">
                <div class="loading-box" v-on:click.stop>
                    <div class="load-bac load-wx">
                        <div class="flex">
                            <div class="flex-1"></div>
                            <van-icon name="cross" size="20"  @click="sendStateFun('send')"/>
                        </div>
                        <img src="../../../../images/icon.png"/>
                        <p>1、先识别手机上的二维码, 如果是公众号请关注。</p>
                        <p>2、再点击“发送报告”</p>
                        <div class="send-box" v-if="module_list.length>0">
                            <div class="send-moule">选择发送的模块</div>
                                <van-checkbox-group v-model="moduleName" direction="horizontal">
                                    <div class="send-checkbox" v-for="(items, index) in module_list" :key="index">
                                        <van-checkbox  shape="square" :name="items.id" checked-color="#07b49a">{{items.module_name}}</van-checkbox>
                                    </div>
                                </van-checkbox-group>
                        </div>
                        <div class="again" @click="sendFun" v-if="module_list.length==0 || module_name.length>0">发送报告</div>
                        <div class="again dim" v-else>发送报告</div>
                    </div>
                </div>
            </div>
            <div class="loading black flex flex-x flex-y" v-if="doctor" @click="sendStateFun('doctor')">
                <div class="overlay" v-on:click.stop>
                    <div v-if="erweim()">
                        <div class="overlay-whith" >
                            <img :src="erweim()"/>
                        </div>
                        <div class="overlay-text">医生扫码</div>
                        <div class="overlay-text size-min">医生用微信扫码为用户开方诊断</div>
                    </div>
                    <div v-else class="overlay-top">
                        <div class="overlay-text">请联系管理人员绑定公众号</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="print">
            <vue-print :user="user" :info="info" :MBItext="MBItext(info.bmi)" :module="module"  />
        </div>
    </div>
</template>
<script>
import vueHeader from '@/components/header';
import vuePrint from '@/views/report/print';
import { mapState, mapActions, mapMutations } from "vuex";
import { isInWechatMPO , makecode } from "@/utils";
import vuePopup from "@/components/popup";
import domain from "@/utils/config";

export default {
    name: 'report',
    data(){
        return{
            bool:false,
            noToken:false
        }
    },
    components:{
        vueHeader,
        vuePopup,
        vuePrint
    },
    created:function(){
        let returns = sessionStorage.getItem('return');
        if(!this.$route.query.return&&returns=='2'){
            this.noToken = true;
        }
        if(this.$route.query.token && !sessionStorage.token){
            sessionStorage.setItem('token',this.$route.query.token);
        }
        this.axiosData({
            report_token:this.$route.params.id
        })
        if(sessionStorage.token){
            this.axiosUser();
        }
    },
    destroyed: function () {
        this.closeFun();
    },
    computed: {
        moduleName:{
            get() { return this.$store.state.report.module_name },
            set(val){
                this.changeFun({
                    key:'module_name',
                    value:val
                });
            }
        },
        ...mapState ("report", ["info","module","loading","login","send","page","user","module_list","module_name","prescribe","doctor",'history']),
    },
    methods: {
        MBItext(bmi) {
            if (bmi <= 18.5) {
                return "偏瘦";
            } else if (bmi >= 18.5 && bmi<= 23.9) {
                return "正常";
            } else if (bmi >= 24 && bmi <= 26.9) {
                return "偏胖";
            } else if (bmi >= 27 && bmi <= 29.9) {
                return "肥胖";
            } else if (bmi >= 30) {
                return "重度肥胖";
            }
        },

        //二维码
        erweim(){
            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            if(equipment){
                let url;
                if(this.bool){
                    url = `https://${equipment.appid}.device${domain}/addUser/${this.$route.params.id}`;
                }else{
                    url = `https://${equipment.appid}.device${domain}/prescribeQuestion/${this.$route.params.id}`;
                }
                return makecode(url);
            }else{
                return false;
            }
        },
        
        // //返回
        // returnFun(){
        //     if(this.$route.query.return==2){
        //         this.$router.back('-1');
        //     }else{
        //         this.$router.push({path: `/reportList/${this.$store.state.report.info.user_id}`});
        //     }
        // },
        
        href(item){
            // 小程序 webview 环境
            if (isInWechatMPO()) {
                let url = this.GetRequest(item);
                let wx = window.wx;
                wx.miniProgram.navigateTo({ url: `/pages/navigator/index?path=${url.path}&id=${url.id}&appid=${url.appid}` });
                return;
            }else{
                window.location.href = item;
            }
        },

        //小程序分割数据
        GetRequest(url){
            var theRequest = {};
            if (url.indexOf("?") != -1) {
                var str = url.split("?");
                theRequest.path = str[0];
                var strs = str[1].split("&");
                for(var i = 0; i < strs.length; i ++) {
                    theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                }
            }
            return theRequest;
        },
        //登录
        loginFun(){
            this.changeFun({
                key:'login',
                value:false
            })
        },
        noLoginFun(){
            this.changeFun({
                key:'history',
                value:false
            })
        },
        //开方
        prescribeFun(){
            this.changeFun({
                key:'prescribe',
                value:false
            })
        },
        //发送报告登录完成的后续工作
        refrFun(){
            this.bool = false;
            this.axiosData({
                report_token:this.$route.params.id
            });
            this.axiosUser();
            this.changeFun({key:'login',value:false});
            this.changeFun({key:'send',value:true});
            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            if(equipment && equipment.send_template_select==1){
                this.sendMain({
                    equipment_code:equipment.equipment_code
                });
            }
        },
        //历史记录登录完成后的后续公众
        historyFun(){
            this.$router.push({
                name:'reportList'
            });
        },
        //登录完成后开方后续工作
        presFun(){
            this.changeFun({key:'prescribe',value:false});
            this.changeFun({key:'doctor',value:true});
        },
        //跳过
        skipFun(bool){
            this.changeFun({key:'prescribe',value:false});
            this.changeFun({key:'doctor',value:true});
            this.bool = bool;
        },
        //关闭模版
        sendStateFun(key){
            this.changeFun({
                key:key,
                value:false
            })
        },
        //发送模版消息
        sendFun(){
          if(sessionStorage.token){
            //发送模版消息
            this.axiosSend({
                data:{
                    token:this.$route.params.id
                }
            })
          }
        },
        //翻页
        pageFun(val){
            this.pageChange(val);
        },
        ...mapMutations ("report", ["change","changeTwo","changeFun","pageChange","closeFun"]),
        ...mapActions ("report", ["axiosData","axiosSend","axiosUser","sendMain"])
    }
}
</script>
<style lang="scss" scoped>
  @import './report';
</style>