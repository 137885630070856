<template>
  <div v-if="info">
      <div class="p1">
        <div class="logo">
            <img src="../../../../images/logo@2x.png"/>
        </div>
        <div class="title">泉脉智能中医健康评估报告</div>
        <div class="time">检测时间：{{info.created_at}}</div>
        <div class="portrait">
            <img :src="user.url" v-if="user.url" />
            <img src="../../../../images/user.png" v-else />
        </div>
        <div class="nikeName">{{info.user_name?info.user_name:(user.nikeName?user.nikeName:'')}}</div>
        <ul class="info">
            <li class="flex">
                <div>年龄：</div>
                <div class="flex-1 bottom">
                    {{info.age}}岁
                </div>
            </li>
            <li class="flex">
                <div>身高：</div>
                <div class="flex-1 bottom">
                    {{info.height}}cm
                </div>
            </li>
            <li class="flex">
                <div>体重：</div>
                <div class="flex-1 bottom">
                    {{info.weight}}kg
                </div>
            </li>
        </ul>
        <div class="warning">郑重提醒：本报告不作医学诊断及购药依据之用</div>
      </div>
      <div class="p2">
          <div class="health-box">
            <h1>健康平衡指数</h1>
            <div class="health-grade flex">
                <div class="grade" v-if="0<info.score && info.score<=50">
                    可能患有疾病，建议就医检查
                </div>
                <div class="grade" v-if="50<info.score && info.score<=65">
                    身体极不平衡，建议马上调理
                </div>
                <div class="grade" v-if="65<info.score && info.score<=80">
                    身体较不平衡，建议马上调理
                </div>
                <div class="grade" v-if="80<info.score && info.score<=90">
                    身体较为平衡，建议适当调理
                </div>
                <div class="grade" v-if="90<info.score && info.score<=100">
                    身体平衡，建议适当调理
                </div>
            </div>
          </div>
          <div class="health-box">
            <h1>体重指数（BMI）</h1>
            <div class="health-grade flex">
                <div class="flex-1">我的指数：<span>{{ info.bmi }}</span>{{MBItext}}</div>
                <div>适合的体重：<span>{{ info.suit_weight }}</span>kg</div>
            </div>
            <ul class="thin flex">
                <li>偏瘦：&lt;18.5</li>
                <li>正常：18.5~22.9</li>
                <li>偏胖：24～26.9</li>
                <li>肥胖：27～29.9</li>
                <li>重度肥胖：≥30</li>
            </ul>
          </div>
          <div class="health-box">
            <h1>中医健康状态辨识</h1>
            <div class="health-grade">
                您当前的中医症候：<span class="stress">{{info.sick_text}}</span>
            </div>
          </div>
          <div class="health-box">
            <h1>证侯解析</h1>
            <div class="health-grade">
                <div v-for="( item, index ) in info.sick_text_array" v-bind:key="index">
                    <div v-html="JSON.parse(info.viscera.syndrome_desc)[item]" class="health-html"></div>
                </div>
            </div>
          </div>
          <div class="health-box">
            <h1>身体健康状态综合分析</h1>
            <div class="health-grade">
                <div class="health-html" v-html="info.check_bmi"></div>
                <div class="health-html" v-html="info.check_estimate" ></div>
            </div>
          </div>
          <div class="health-box" v-for="(items, index) in module.data" :key="index">
              <h1>{{items.module_name}}</h1>
              <div class="health-module">
                  <div v-for="( item2, index2 ) in items.description_list" v-bind:key="index2" class="module">
                      <div v-if="item2.list">
                          <span class="orange">{{item2.syndrome_name}} · </span>
                          <div v-for="( item3, index3 ) in item2.list" v-bind:key="index3" class="moduleins">
                              <div v-for="( item4, index4 ) in item3.module_value" v-bind:key="index4" class="moduleins">
                                  <div v-if="horizontalFun(item4).value" class="moduleins">
                                      {{horizontalFun(item4).value}}
                                  </div>
                                  <div v-if="verticalFun(item4).value" :class="{moduleins:index4==0&&index3==0}">
                                      <div class="moduleins" :class="{moduletop:index4==0&&index3!==0}">{{titleFun(`${item4.title}`)}}</div><br v-if="br.indexOf(item4.title)>=0"><div class="moduleins modulecolor">{{verticalFun(item4).value}}</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
export default {
    name: 'print',
    props: ["user",'info','MBItext','module'],
    data(){
        return{
            horizontal:['生活指导','饮食指导','经络名称','音乐曲目','经典药方名称','药膳名','种类','药浴方名称','穴位','药茶名称','茶名'],   //横
            vertical:['名称','成分','可辅助搭配以下饮片增加其效果','配方','药物组成','药浴包配方','材料','做法','改善方案','慢病说明','重点防范的慢病'],
            notitle:['名称'],
            br:['可辅助搭配以下饮片增加其效果']
        }
    },
    methods:{
        //筛选
        horizontalFun(items){
            for(let i=0,n=this.horizontal.length;i<n;i++){
                if(items.title==this.horizontal[i]){
                    return items;
                }
            }
            return {};
        },
        verticalFun(items){
            for(let i=0,n=this.vertical.length;i<n;i++){
                if(items.title==this.vertical[i]){
                    return items;
                }
            }
            return {};
        },
        titleFun(items){
            for(let i=0,n=this.notitle.length;i<n;i++){
                if(items!==this.notitle[i]){
                    return `${items}：`;
                }
            }
            return '';
        }
    }
}
</script>
<style lang="scss" scoped>
  @import './print';
</style>